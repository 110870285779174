import axios from 'axios';
import axiosRetry from 'axios-retry';



export const getAccessTokenFromStorage = () =>
  new Promise(async (resolve, reject) => {
    try {
      const value = localStorage.getItem
('userToken');
      resolve(value);
    } catch (error) {
      console.warn(error);
    }
  });
export const setHeaders = async (headers = null) => {
  let headerData;
  return getAccessTokenFromStorage().then((token) => {



    headerData = {
      
      "Content-type": "application/json",
      
      Accept: 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
     
    };

    if (headers) {
      headerData = { ...headerData, ...headers };
    }

    return headerData;
  });
};

const createAxios = async () => {
  let headers = await setHeaders();  // This function should handle any async operations needed for setting headers.
  const axiosInstance = axios.create({
      baseURL: 'https://production-api.virtuesshop.co',
      headers: headers,
  });

  // Configure axios-retry
  axiosRetry(axiosInstance, {
      retries: 3, // Number of retry attempts
      retryCondition: (error) => {
        // Check if the response has a status code that should not trigger a retry
        if (error.response && [400, 401, 405, 409, 500].includes(error.response.status)) {
            return false;
        }
        // Other retry conditions
        return error.code === 'ECONNABORTED' ||
               error.message.includes('Network Error') ||
               error.message.includes('TIMED_OUT') ||
               error.message.includes('ERR_TIMED_OUT') ||
               error.message.includes('CONNECTION_CLOSED') ||
               error.message.includes('ERR_CONNECTION_CLOSED') ||
               axiosRetry.isNetworkOrIdempotentRequestError(error) ||
               (error.response && [502, 503, 504].includes(error.response.status));
    },
    
      retryDelay: axiosRetry.exponentialDelay  // Exponential back-off delay between retries
  });

  return axiosInstance;
};


export default createAxios;