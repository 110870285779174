import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './styles.css';
import GuestNavbar from './components/Navbar/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer/Footer';

function App() {
  return (
   <BrowserRouter>
   <GuestNavbar/>
   <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
