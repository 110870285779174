import React, { useEffect, useState,useRef } from 'react'
import { Row,Col,Image, Card,Button,Carousel} from 'react-bootstrap'

import hero1 from '../assets/hero-1.png'
import cart from '../assets/cart-icon.svg'
import profile from '../assets/profile-pic.png'
import mask1 from '../assets/mask-1.png'
import mask2 from '../assets/mask-2.png'
import mask3 from '../assets/mask-3.png'
import productRoute from '../services/productRoute'
import { useAppContext } from '../AppContext'
import arrowRight from '../assets/arrow-right.svg'

import individual from '../assets/individual.png'
import family from '../assets/family.png'
import business from '../assets/business.png'
import arrowLeft from '../assets/arrow-left.svg'
import cCurve from '../assets/dollar-icon.svg'
import source from '../assets/source-icon.svg'
import star from '../assets/star.svg'
import arrowCircle from '../assets/arrow-circle.png'
import save from '../assets/save-icon.svg'
import bodyBg from '../assets/bg-image.png'
import exploreBg from '../assets/exploreBg.png'
import voucher from '../assets/voucher.png'
import virtueBox from '../assets/virtue-box.png'
import refer from '../assets/refer.svg'
import Slider from "react-slick";
import { trackEvent } from '../mixpanel'
import { Helmet } from 'react-helmet-async'


const opinions = [
  {id:1,
  opinion:'The shopping was very good as usual. Very fresh! The bananas were good!! Kudos to your shoppers.',
  name:'Nike',
  opinionImage:profile
  },
  {id:2,
    opinion:'Shopping experience was good. Keep up the good work. ',
    name:'Banke',
    opinionImage:profile
    },
    {id:3,
      opinion:'The items went way and beyond my expectations. They were fresh, large and luscious! I got some excesses. I am greatly impressed.',
      name:'Becks',
      opinionImage:profile
      },
      {id:4,
        opinion:"I've gotten my order, thank you so much. The tomatoes is so plenty.",
        name:'Kenny',
        opinionImage:profile
        },
      {id:5,
        opinion:'Items have been received with many thanks. I look forward to ordering again.',
        name:'Veronica',
        opinionImage:profile
        },
        {id:6,
          opinion:"You sent me big plantains. I can't remember the last time I saw this in the market. It's just tiny tiny things they are shading in the market now. The plantains are so fresh and fine. Thank you. ",
          name:'Adaeze',
          opinionImage:profile
          },
]

const exploreOptions = [
  {id:1,
  options:'Skip the store and get curated selection of fresh, local groceries delivered straight to your door. Filled with seasonal produce and everyday essentials to keep your kitchen stocked. Convenience has never been so fresh.',
  title:'Specially Curated Boxes, Just For You!',
  optionImage:virtueBox,
  header:'Subscription Boxes',
  cta:'Subscribe Now',
  url:'https://app.virtuesshop.co/subscriptions'
  },
  {id:2,
    options:'Tell your friends about Virtue’s Shop and both of you can save! For every friend you refer, you’ll get 10% off your next order, and they will get a discount too. It’s a win win.',
    header:'Refer & Earn',
    title:'Referral Program',
  optionImage:refer,
  cta:'Start Earning',
  url:'https://app.virtuesshop.co/login'
    },
    {id:3,
      options:"Looking for the perfect gift? Give the gift of deliciousness with a Virtue's Shop gift voucher. Let your loved ones choose their own groceries and enjoy the convenience of our online shopping experience. ",
      title:'There’s A Voucher For Every Occasion',
  optionImage:voucher,
  header:'Voucher',
  cta:'Give the Gift of Taste',
  url:'https://app.virtuesshop.co/vouchers'
      },
    ]

export const sanitizeProductName = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, '');    // Remove leading or trailing hyphens
};
const Home = () => {

  const {showToast, isMobile} = useAppContext();
  const [loading, setLoading] = useState(false);
  
  const [isPaused, setIsPaused] = useState(false);
  const { getTrendingProducts}= productRoute();

  const [trendingProducts, setTrendingProducts] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollLeft, setMaxScrollLeft] = useState(0);
  const scrollContainerRef = useRef(null);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Define isMobile based on screen width
  const isTrendingMobile = screenWidth <= 768;
  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true, 
    autoplaySpeed: 5000, 
    centerPadding: '60px', 
    focusOnSelect: true, 
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 3, 
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  

  // Track screen size changes



  

const handleScroll = (direction) => {
  const container = scrollContainerRef.current;
  const scrollAmount = direction === 'left' ? -container.clientWidth * 0.8 : container.clientWidth * 0.8;
  container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
};

const checkScrollPosition = () => {
  const container = scrollContainerRef.current;
  if (container) {
    const currentScroll = container.scrollLeft;
    const maxScroll = container.scrollWidth - container.clientWidth;
   
    setScrollPosition(currentScroll);
    setMaxScrollLeft(maxScroll);
  }
};
useEffect(()=> {
  trackEvent('Website Visit', {
    attempted_at: new Date().toISOString(),
  })

},[])

// Fetch trending products on mount
useEffect(() => {
  getTrendingProducts(setLoading, showToast, setTrendingProducts);
}, []);

useEffect(() => {
  const handleResize = () => setScreenWidth(window.innerWidth);

  window.addEventListener('resize', handleResize);
  
  // Clean up the event listener on component unmount
  return () => window.removeEventListener('resize', handleResize);
}, []);

// Calculate width based on screen size
const getWidth = () => {
  if (isTrendingMobile) {
    return '42%';
  } else if (screenWidth <= 1440) {
    return '31%';
  }else if (screenWidth <= 1620) {
    return '23%';
  }
   else if (screenWidth <= 1920) {
    return '20%';
  } else if (trendingProducts.length <= 3) {
    return '35%';
  } else {
    return '30%';
  }
};

// Add scroll event listener and check scroll position
useEffect(() => {
  const container = scrollContainerRef.current;
  if (container) {
    container.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition(); // Initial check
    return () => container.removeEventListener('scroll', checkScrollPosition);
  }
}, [trendingProducts]);

// Update scroll position after loading is done
useEffect(() => {
  if (!loading && trendingProducts.length > 0) {
    checkScrollPosition();
  }
}, [loading, trendingProducts]);




const handleImageClick = () => {
  setIsPaused(!isPaused);
};

  return (
    <>
    <Helmet>
        <title>Virtue's Shop</title>
        <meta name="description" content="Bringing the markets to you! Best grocery delivery service in Africa> Curate Your Cart,Taste the Convenience of Bringing the markets to you!" />
        <link rel="canonical" href="https://virtuesshop.co/" /> 

      </Helmet>
    <div style={{
      backgroundImage: `url(${bodyBg})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
      opacity:'',
      position: 'relative',
      overflowX:'hidden'}} className='mx-0  px-0 pb-0'>
      <section className='position-relative mt-lg-2 pt-5 px-3 px-lg-5 bg-white hero-section' >
      <div className='d-none d-md-block position-absolute' style={{bottom:isMobile ? '39%':'29%', left:isMobile ? '72%':'59%',}}><img src={mask1} alt='mask1'/></div>
      <div className='d-none d-md-block  position-absolute' style={{bottom:isMobile ? '39%':'29%', right:isMobile?'5%':'10%',}}><img src={mask1} alt='mask1'/></div>
      <div className=' d-none d-md-block  position-absolute' style={{top:isMobile ? '15%':'30%', left:isMobile ? '81%':'43%',}}><img src={mask2} alt='mask2'/></div>
      <div className=' d-none d-md-block  position-absolute' style={{top:'8%', right:' 25%',}}><img src={mask3} alt='mask3'/></div>
   
      <div className="d-block d-md-none clearfix align-items-center">
  <img 
    src={hero1} 
    alt="hero1" 
    style={{
      float: 'right', 
      marginLeft: '1rem', 
      marginTop: '1.5rem', 
      width: '50%',
    }} 
  />

<h1 style={{fontFamily:'Poppins', lineHeight:'40px'}}>Curate Your Cart,
  <br/>
  <span style={{color:'#469B12',fontFamily:'Poppins'}}>Taste the</span> Convenience</h1>
          <h3 className='mt-1' style={{fontWeight:'400', color:'#1D2939'}}>Discover a world of flavors and essentials at your fingertips. From fresh produce to pantry staples, we've got you covered.</h3>
       <Button href='https://app.virtuesshop.co/' target="_blank" onClick={() => 
  trackEvent('Redirect to App from Website', {
    attempted_at: new Date().toISOString(),
  })
}
    rel="noopener noreferrer" className='align-items-center mt-2 mt-md-0' style={{borderRadius:'80px'}}>Start Shopping &nbsp; <img src={cart}  style={{width:'24px'}}alt='cart'/> </Button>
       
</div>



        <Row className='d-none d-md-flex align-items-center gap-0 ' >
          <Col xs={9} md={6} className='ps-xl-5 ps-lg-0 pe-md-0'>
          <div className='pt-3 pt-lg-0 d-flex flex-column gap-4 w-100'>
          <h1 style={{fontFamily:'Poppins', lineHeight:isMobile ? '35px': '75px'}}className=''>Curate Your Cart, 
          <span style={{color:'#469B12', fontFamily:'Poppins'}}>Taste the</span> Convenience</h1>
          
          <h3 style={{fontWeight:'400', color:'#1D2939'}}>Discover a world of flavors and essentials at your fingertips. From fresh produce to pantry staples, we've got you covered.</h3>
          <div>   <Button href='https://app.virtuesshop.co/' target="_blank"  
    rel="noopener noreferrer" className='align-items-center' style={{borderRadius:'80px'}} onClick={() => 
      trackEvent('Redirect to App from Website', {
        attempted_at: new Date().toISOString(),
      })
    }>Start Shopping &nbsp; <img src={cart}  style={{width:'24px'}}alt='cart'/> </Button></div>
       
          </div>
          </Col>
          <Col md={6} className='d-none d-md-block'>
          <Image fluid src={hero1} alt='hero1'/>
          </Col>
        </Row>
      </section>
     
      <section id='section2' className=' pt-4  bg-white position-relative' style={{marginBottom:isMobile ? '60px':'80px'}} >
    
        <div className='px-3 px-md-5 mx-lg-4 position-relative'>
        
        <div className='text-center  mb-4 mb-lg-5'>
          <h2 style={{fontWeight:'500'}}>Shelf-Worthy Selections</h2>
          <h3 style={{fontWeight:'400'}}> Enjoy great prices on delicious groceries. Save up to <span style={{color:'#469B12', fontWeight:'700'}}>25%</span> on your shopping basket.</h3>
        </div>
        {loading ? <div className='justify-content-center align-items-center text-center' style={{ padding: '50px 0' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
        :
        <div className="px-3">
        {trendingProducts.length > 0 ? (
          <>
            <div
              className="d-flex scroll-container"
              style={{ scrollSnapType: 'x mandatory', gap: '1rem', WebkitOverflowScrolling: 'touch', overflowX:'auto', overflowY: 'hidden' }}
              ref={scrollContainerRef}
            >
              {trendingProducts.map((product, index) => {
                 const sanitizedProductName = sanitizeProductName(product?.productName); 
                 const productId = product?.sku;
                 const productUrl = `https://app.virtuesshop.co/products/${sanitizedProductName}/${productId}`;

                 return (
                  <div
                    key={product?.id}
                    style={{ flex: '0 0 auto',    width: getWidth(), position: 'relative' }} className='mb-3'
                  >
                    <Card className="d-none d-md-flex h-100" style={{ boxShadow: '0px 4px 4px 0px #00000040', border:'1px solid #C6E0B6',backgroundColor:'#C6E0B64D', height:'170px'}}>
                    <div className='d-flex gap-3 align-items-center' >
                      <div style={{height:'170px'}} className='py-2 ps-2 m-0 '>
                  <img src={product?.productImage} style={{height:'100%',objectFit:'cover', borderRadius:'8px' }} className='p-0 m-0 product-image' alt='product'/>
                  </div>
                  <div className=''>
                 
                      <h4 style={{fontWeight:'700', color:'#34405499',fontSize:isMobile ? '18px':'20px' }}>
                      {product?.productName}
                      </h4>
                     <div className='d-block d-xl-flex gap-2'>
                      <h5 style={{ fontFamily: 'Outfit', color: '#1D2939', fontWeight: '700', fontSize:isMobile ? '17px':'18px' }} className="my-auto">
                              {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.price)}
                            </h5>
                             
                    <Button onClick={() => {
  trackEvent('View Product from Website', {
    attempted_at: new Date().toISOString(),
  })

;window.location.href = productUrl}}className="d-flex text-center justify-content-center align-items-center p-1" style={{ backgroundColor:'#469B12', fontSize:'13px'}}>
                      Add to Cart
                    </Button>
                    </div>
                     
                  </div>
                </div>
             
                    </Card>
                    <Card className="d-block d-md-none h-100 pb-2" style={{ boxShadow: '0px 4px 4px 0px #00000040', border:'1px solid #C6E0B6',backgroundColor:'#C6E0B64D',  }}>
                     
                        <Card.Img
                          src={product?.productImage}
                            alt='product'
                          style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', height: '100%', maxHeight:'120px', objectFit: 'cover' }}
                        />
              
                    
                      <Card.Body className="px-2 pt-1 pb-2 h-100 d-flex flex-column">
                      <h4 style={{fontWeight:'700', color:'#34405499',fontSize:isMobile ? '16.5px':'20px' }}>
                      {product?.productName}
                      </h4>
                   
                      <h5 style={{ fontFamily: 'Outfit', color: '#1D2939', fontWeight: '700', fontSize:'18px' }} className="">
                              {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.price)}
                            </h5>
                    <div>
                            <Button onClick={() => {
  trackEvent('View Product from Website', {
    attempted_at: new Date().toISOString(),
  })

;window.location.href = productUrl}} className="d-flex text-center justify-content-center align-items-center p-1 my-2" style={{ backgroundColor:'#469B12', fontSize:'13px'}}>
                      Add to Cart
                    </Button>
                    </div>
                 
                         
                      
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
            </div>
           
              <Button variant="secondary" className="position-absolute " onClick={() => handleScroll('left')} style={{borderRadius:'50%', border:'none',backgroundColor:'#469B1226', opacity:'0.75', top:'60%', left:isMobile ?'-2%': '1%',padding:'5px 10px'}}>
                {/* &lt; */}
                <img src={arrowLeft} alt='arrow'  style={{width:'17px', height:'17px'}}/>
              </Button>
            
           
              <Button variant="secondary" className="position-absolute" onClick={() => handleScroll('right')} style={{borderRadius:'50%',top:'60%', backgroundColor:'#469B1226', border:'none',opacity:'0.75', right:isMobile ?'-2%': '1%', padding:'5px 10px'}}>
                {/* &gt; */}
                <img src={arrowRight} alt='arrow' style={{width:'17px', height:'17px'}}/>
              </Button>
            
  
          </>
        ) : (
          <div className="text-center fw-medium" style={{ padding: '100px' }}>
            No products available
          </div>
        )}
      </div>}
        </div>
      </section>
      <div  style={{ backgroundColor: '#469B12', border: '1px solid' }} className='px-0 py-2 py-md-3 text-white'>
  <div className='text-scroll-container'>
    <div className='scrolling-text'>
      <div className='text-group'>
        {/* Text Group 1 */}
        <div className='d-flex gap-4 gap-lg-5 px-0'>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={cCurve} alt='icon' style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }} className='my-auto'>Best grocery prices</h4>
          </div>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={source} alt='icon' style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }} className='my-auto'>Sustainably sourced</h4>
          </div>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={save} alt='icon' style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }} className='my-auto'>Save up to 25% on shopping</h4>
          </div>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={star} alt='icon'  style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }} className='my-auto'>Quality Products</h4>
          </div>
        </div>
        {/* Clone of the text for smooth scrolling */}
        <div className='text-group'>
        <div className='d-flex gap-4 gap-lg-5 px-0'>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={cCurve} alt='icon' style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }} className='my-auto'>Best grocery prices</h4>
          </div>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={source} alt='icon' style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }} className='my-auto'>Sustainably sourced</h4>
          </div>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={save} alt='icon' style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }} className='my-auto'>Save up to 25% on shopping</h4>
          </div>
          <div className='d-flex gap-2 gap-lg-3 align-items-center'>
            <img src={star} alt='icon' style={{ width: isMobile ? '16px' : '30px' }} />
            <h4 style={{ fontWeight: '500', fontSize: isMobile && '12px' }}className='my-auto'>Quality Products</h4>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section id='section1' className='bg-white'  style={{paddingBottom:isMobile ? '50px':'80px'}}>
  <div className='px-3 px-md-5 pt-5' style={{
      backgroundImage: `url(${exploreBg})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat',
      backgroundColor:'#E1EFD9',
      position: 'relative',}}>
          <div className='d-none d-md-block position-absolute' style={{top: isMobile ? '0%':'10%', left:'8%',}}><img src={mask1} alt='mask1'/></div>
      <div className='d-none d-md-block position-absolute' style={{top: isMobile ? '2%':'5%', right:'8%',}}><img src={mask1} alt='mask1'/></div>
     
  <div className='d-flex flex-column text-center justify-content-center pt-md-3 pt-lg-5'>
  <h1 style={{fontWeight:'500', fontSize:isMobile?'30px':'52px'}} className=''>There’s More to Explore</h1>
  <h3 style={{fontWeight:'400', maxWidth:"40rem"}} className='mx-md-auto'>Save More, Stress Less – With Our Subscription Boxes, Referral Program  & Gift Vouchers!</h3>
  </div>
  <div className='px-lg-5 pt-lg-2 pb-2'>
  <Carousel
data-bs-theme="dark"
controls={false}
  indicators={true}
  interval={isPaused ? null : 9000}
  className="custom-carousel"
>
  {exploreOptions.map((option, index) => (
    <Carousel.Item key={index} className='text-decoration-none' onClick={handleImageClick}>
      <Row className='px-3 px-lg-0 d-flex align-items-center gap-2 gap-md-5 py-4 py-md-5 mb-5'>
     
          <Col xs={12} md={true} className='pe-lg-4 d-flex text-center justify-content-center mb-3'>
          <Image fluid src={option?.optionImage} style={{height : isMobile ? '200px' :'100%', }} />
          
          </Col>
          <Col xs={12} md={true}  className='ps-lg-4 align-items-center'  >
          <div className='d-flex flex-column gap-3'>
         <h3 style={{fontWeight:'600', fontSize:isMobile ? '18px': '32px', color:'#344054'}}>{option?.title}</h3>
         <h3 style={{fontWeight:'300', color:'#344054'}}>{option?.options}</h3>
         <div>
         <Button href={option?.url} target="_blank" onClick={()=>  trackEvent(`View ${option?.header} from Website`, {
    attempted_at: new Date().toISOString(),
  })}  
    rel="noopener noreferrer" className='align-items-center w-auto' style={{borderRadius:'80px'}}>{option?.cta} &nbsp; &nbsp;<img src={arrowCircle}  alt='arrow' style={{width:isMobile ? '24px':'45px'}}/> </Button>
    </div>
    </div>
          </Col>
      
      </Row>
    </Carousel.Item>
  ))}
</Carousel>
</div>
  </div>
  </section>
<section id='section3' className='px-3 px-md-5' style={{marginBottom:isMobile ? '70px':'100px'}}>
  <div className='bg-white pt-lg-5 pb-lg-4 pb-md-3 pb-2'>
  <h2 style={{fontWeight:'500'}} className='text-center bg-white'>Discover How We Serve You</h2>
  </div>
  <Row className='d-flex justify-content-center mt-4 mt-md-3 mx-lg-2'>
    <Col xs={12} md={6} className='mb-3 mb-md-4 pe-lg-3'>
    <Card style={{borderRadius:'12px', backgroundColor:'#E1EFD9', border:'none'}} className='p-3 h-100'>
      <Row className='align-items-center'>
        <Col xs={5}>
        <Image fluid src={individual} alt='individual'/>
        </Col>
        <Col xs={7}>
     <h3 style={{fontSize: isMobile ? '18px':'28px', color:'#1D2939', fontWeight:'500'}}>For Individuals</h3>
     <h5 style={{fontWeight:'400'}}>Life’s too busy to stress over groceries. Experience the convenience of high-quality produce and pantry staples delivered right to your door. Shop with ease and enjoy the taste of freshness.</h5>
        </Col>
      </Row>
    </Card>
    </Col>
    <Col xs={12} md={6} className='mb-3 mb-md-4 ps-lg-3'>
    <Card style={{borderRadius:'12px', backgroundColor:'#E1EFD9', border:'none'}} className='p-3 h-100'>
      <Row className='align-items-center'>
        <Col xs={5}>
        <Image fluid src={family} alt='family'/>
        </Col>
        <Col xs={7}>
     <h3 style={{fontSize: isMobile ? '18px':'28px', color:'#1D2939', fontWeight:'500'}}>For Families</h3>
     <h5 style={{fontWeight:'400'}}>From weekly meal planning to stocking up on essentials, we’re here to make family grocery shopping a breeze. Enjoy a wide selection of nutritious produce, kid-friendly snacks, and convenient meal options.</h5>
        </Col>
      </Row>
    </Card>
    </Col>
    <Col xs={12} md={6} className='mb-2 mb-md-4 px-2'>
    <Card style={{borderRadius:'12px', backgroundColor:'#F9706633', border:'none'}} className='p-3 h-100'>
      <Row className='align-items-center'>
        <Col xs={5}>
        <Image fluid src={business} alt='business'/>
        </Col>
        <Col xs={7}>
     <h3 style={{fontSize: isMobile ? '18px':'28px', color:'#1D2939', fontWeight:'500'}}>For Businesses</h3>
     <h5 style={{fontWeight:'400'}}>Running a restaurant, hotel or school? Our bulk orders and specialized deliveries ensure you get fresh, local ingredients and essential supplies. Keep your business thriving with our dependable service and top-notch products that cater to your professional needs.</h5>
        </Col>
      </Row>
    </Card>
    </Col>
  </Row>
  {/* <div className='text-center mt-3'>
<Button href='https://app.virtuesshop.co/' target="_blank"  
    rel="noopener noreferrer" className='align-items-center  w-auto' style={{borderRadius:'80px'}}>Explore Now &nbsp; &nbsp; <img src={arrowCircle}  alt='arrow' style={{width:isMobile ? '24px':'50px'}}/> </Button>
</div> */}
</section>
<section className='py-5 px-md-5 position-relative' style={{
      backgroundColor:'#E1EFD9'}}>
  <div className='d-flex flex-column text-center justify-content-center'>
  <h1 style={{fontWeight:'500', fontSize:isMobile?'30px':'52px'}} className=''>Basketful of Opinions</h1>
  <h3 style={{fontWeight:'400', maxWidth:"48rem"}} className='mx-md-auto'>Taste the excitement in every review. Here's what our Virtue's Shoppers are buzzing about.</h3>
  </div>
  <div className='pt-3 pt-md-5' >
  <Slider {...settings}>
      {opinions.map((opinion, index) => (
        <div key={index} className="opinion-slide">
          <Card
            className={`opinion-card p-4`}
            style={{
              borderRadius: '40px',
              boxShadow: '0px 10px 10px 3px #1D29391A',
              transform: 'scale(0.85)', // Default size for non-centered slides
            }}
          >
            <h5 style={{ color: '#344054', fontWeight: '500' }}>
              {opinion?.opinion}
            </h5>
            <div className='d-flex gap-2 align-items-center detail-block mt-4'>
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: 'rgba(70, 155, 18, 0.4)',
                color: '#000000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: '700',
                fontSize: isMobile ?'16px':'20px',
              }}
            >
              {opinion.name[0].toUpperCase()}
            </div>
              <h4 style={{ color: '#469B12' }} className='my-auto'>{opinion?.name}</h4>
            </div>
          </Card>
        </div>
      ))}
    </Slider>



  </div>
  </section>
    </div>
    </>
  )
}

export default Home
