import React from 'react'
import phone from '../../assets/phone.svg'
import mail from '../../assets/mail.svg'
import twitter from '../../assets/twitter.svg'
import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import whatsapp from '../../assets/whatsapp.svg'
import linkedin from '../../assets/linkedin.svg'
import logo from '../../assets/vs-logo.svg'

import {Col, Row , Form,Button} from 'react-bootstrap'
import { useAppContext } from '../../AppContext'

const Footer = () => {
        const {isMobile} = useAppContext();
  
  return (
    <div id="footer" style={{backgroundColor:'white', color:'#557B42'}} >
      <div className='py-3 px-3 px-lg-5 mx-lg-4'>

        
       
       <Row className='pt-3 gap-lg-3' id="navbar-example2">
        <Col xs={12} md={5} className=' mb-3 mb-md-0 '>
          <div className='d-flex flex-column gap-3'>
          
          <div className='d-flex gap-3 align-items-center'>
          <img src={logo} style={{width:'70px'}} alt='logo'/>
          <h3 className='my-auto' style={{fontWeight:'700'}}>Virtue's Shop</h3>
          </div>
        <h5 style={{fontWeight:'500',fontSize:isMobile? '14px':'18px' }} className='mb-0'>Get the latest updates on exclusive offers, delicious recipes, and more! Join the Virtue's family today.</h5>
        <div className='d-flex gap-2 mb-2 mb-md-0 mx-0 p-0'>
        <Form.Control type='email' placeholder='Enter your email'/>
       <Button>Subscribe</Button></div>
        <div className='d-none d-md-flex gap-3 align-items-center mb-lg-1'>
           <img src={mail} alt='mail'/>
           <h5 className='my-auto'  style={{ cursor: "pointer",fontWeight:'400' }}
            > <a
            href="mailto:support@virtuesshop.co"
          style={{color:'#557B42'}}
          >support@virtuesshop.co
          </a></h5>
           </div>
        <div className='d-none d-md-flex gap-3 align-items-center'>
        <img src={phone} alt='phone'/>
            <h5 className='my-auto' style={{ cursor: "pointer", fontWeight:'400' }}
            ><a    style={{color:'#557B42'}} href="tel:+2349167682871" className="text-decoration-none">+234 9167682871</a></h5>
            </div>
            <div className='d-none d-md-flex gap-3 align-items-center'>
            <a href='https://web.facebook.com/people/VirtuesShop/61556578874811/?mibextid=LQQJ4d' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={facebook} alt='social-icon'/></a>
            <a href='https://www.instagram.com/virtuesshop_?igsh=cmcxazFqbTQwZmxx' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={instagram} alt='social-icon'/></a>
            <a href='https://x.com/VirtuesShop?t=pVoMBdmEVgRNA6LEvm4A-Q&s=08' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}} className='text-white '><img src={twitter} alt='social-icon'/></a>
            <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={whatsapp} alt='social-icon' className='' /></a>
            <a href='https://www.linkedin.com/company/virtuesshop/' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={linkedin} alt='social-icon'/></a>
          </div>
            
</div>
        </Col>
      
            <Col xs={6} md={2} className='mb-4'>
            <div className='d-flex flex-column gap-2 gap-lg-3'>
            <h3 className='mb-2'>Company</h3>
            <h5  className='mb-1 py-0' style={{ cursor: "pointer", fontWeight:'400' }}
          >About Us</h5>
            <a className='text-decoration-none' href="https://app.virtuesshop.co/faq" target="_blank"  
           rel="noopener noreferrer" style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }}
           >FAQs</a>
            <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
            rel="noopener noreferrer" className='text-decoration-none ' style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }}
            >Join our Community</a>
            <h5  style={{ cursor: "pointer", fontWeight:'400',fontSize:isMobile? '14px':'18px' }} className='my-0 py-0'
          >Track Your Orders</h5>
          </div>
            </Col>
            <Col xs={6} md={3} className='mb-4 d-flex justify-content-md-center'>
            <div className='d-flex flex-column gap-2 gap-lg-3'>
            <h3 className='mb-2'>Compliance</h3>
           <a className='text-decoration-none' href="https://app.virtuesshop.co/privacy-policy" target="_blank"  
            rel="noopener noreferrer" style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }}
            >Privacy Policy</a>
           <a className='text-decoration-none' href="https://app.virtuesshop.co/terms" target="_blank"  
            rel="noopener noreferrer" style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }}
            >Terms of Use</a>
           <a className='text-decoration-none' href="https://app.virtuesshop.co/refund-policy" target="_blank"  
            rel="noopener noreferrer" style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }}
            >Return & Refund Policy</a>
           <a className='text-decoration-none' href="https://app.virtuesshop.co/shipping-policy" target="_blank"  
             rel="noopener noreferrer" style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }}
             >Shipping & Delivery Policy</a>
          </div>
            </Col>
            <Col xs={6} md={1} className='mb-4'>
            <div className='d-flex flex-column gap-2'>
            <h3 className='mb-2'>Locations</h3>
          
            <h5  style={{ cursor: "pointer", fontWeight:'400' ,fontSize:isMobile? '14px':'18px' }}
          >Lagos</h5>
            <h5  style={{ cursor: "pointer", fontWeight:'400' ,fontSize:isMobile? '14px':'18px' }}
            >Ogun</h5>
       
           
          </div>
            </Col>
            <Col xs={6} md={4} className='d-block d-md-none mb-4'>
            <h3 className='mb-2'>Social</h3>
            <div className='d-flex flex-column gap-3 mt-3'>
            
        
        <div className='d-flex gap-2 align-items-center mb-lg-1'>
           <img src={mail} alt='mail' style={{width:'16px'}}/>
          <a
            href="mailto:support@virtuesshop.co"
            style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }}
          > support@virtuesshop.co
          </a>
           </div>
        <div className='d-flex gap-2 align-items-center'>
        <img src={phone} alt='phone'  style={{width:'16px'}}/>
           <a   style={{color:'#557B42', cursor: "pointer", fontWeight:'400', fontSize:isMobile? '14px':'18px' }} href="tel:+2349167682871" className="text-decoration-none"> 
            +234 9167682871</a>
            </div>
            <div className='d-flex gap-2 align-items-center'>
            <a href='https://web.facebook.com/people/VirtuesShop/61556578874811/?mibextid=LQQJ4d' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={facebook} alt='social-icon'  style={{width:'16px'}}/></a>
            <a href='https://www.instagram.com/virtuesshop_?igsh=cmcxazFqbTQwZmxx' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={instagram} alt='social-icon'  style={{width:'16px'}}/></a>
            <a href='https://x.com/VirtuesShop?t=pVoMBdmEVgRNA6LEvm4A-Q&s=08' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}} className='text-white '><img src={twitter} alt='social-icon'  style={{width:'16px'}}/></a>
            <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={whatsapp} alt='social-icon' className=''  style={{width:'16px'}}/></a>
            <a href='https://www.linkedin.com/company/virtuesshop/' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}><img src={linkedin} alt='social-icon'  style={{width:'16px'}}/></a>
          </div>
            
</div>
        </Col>
         
       </Row>
      </div>
   
    </div>
  )
}

export default Footer